@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  /**
   * Buttons.
   */
  .primary-button {
    @apply px-6 py-2.5 bg-russian-green-400 text-white hover:bg-russian-green-300 hover:text-champagne-50 rounded-md transition duration-500 font-bold; 
  }

  .secondary-button {
    @apply px-6 py-2.5 bg-transparent border border-russian-green-400 text-russian-green-400 hover:bg-russian-green-300 hover:text-champagne-50 rounded-md transition duration-500 font-bold;
  }

  .cta-button {

  }

  .icon-button {
    @apply inline-flex justify-self-end items-center justify-center px-2 py-2.5 rounded-md transition duration-500;
  }

  /**
   * Anchors/Links.
   */
  .navbar-link  {
    @apply mr-1 px-2 py-1 lg:px-4 lg:py-2 hover:bg-russian-green-100 hover:text-champagne-500 transition-colors duration-500 rounded-md;
  }

  .navbar-link.active {
    @apply bg-russian-green-300 text-champagne-50;
  }

  /**
   * Drawer
   */
  .drawer {
    @apply fixed bg-champagne-300 outline-none overflow-x-hidden overflow-y-auto z-30
  }

  .drawer-overlay {
    @apply fixed left-0 top-0 w-full h-full bg-raisin-900 opacity-80 z-30;
  }

  .drawer-header {
    @apply p-5 w-full font-bold bg-cornsilk-300 text-center text-2xl font-serif;
  }

  .drawer-subheader {
    @apply px-5 py-2 w-full bg-cornsilk-300 font-serif font-bold text-lg mb-2
  }

  .drawer-content {
    @apply container mx-auto relative;
  }

  .drawer-close-button {
    @apply icon-button w-5 h-5 absolute right-0 top-0 m-5 bg-red-600 font-bold text-white;
  }

  .drawer-footer {
    @apply flex w-full gap-5 sticky bottom-0 mt-auto p-5 bg-cornsilk-300;
  }

  /**
   * Shop Page.
   */
  .shop-page-section {
    @apply relative rounded-xl border-2 border-cornsilk-300 p-5 gap-5;
  }

  .shop-page-section-header {
    @apply font-serif font-semibold bg-champagne-300 absolute right-0 top-0 -mt-4 mr-4 p-1;
  }

  /**
   * Product Card/Modal.
   */
  a.product-card:hover > .product-card-content > .title,
  a.product-card:hover > .product-card-content > .price {
    @apply text-russian-green-400 transition duration-500;
  }

  .product-modal-description > p {
    @apply mb-0;
  }

  .product-modal-gallery-image {
    @apply origin-center rotate-45;
  }

  .product-modal-gallery-image:nth-of-type(1n+2) {
    @apply -ml-4;
  }

  .product-modal-gallery-image:nth-of-type(2n+2) {
    @apply translate-y-1/2;
  }

  .product-modal-gallery-image:nth-of-type(2n+1) {
    @apply -translate-y-1/4;
  }

  /**
   * Form controls.
   */
  .radio-button-wrapper {
    @apply flex items-center;
  }

  .radio-button {
    @apply w-5 h-5 inline-block mr-2 rounded-full border border-space-cadet-900 bg-white;
  }

  .radio-button-label {
    @apply flex items-center cursor-pointer font-sans text-base;
  }

  .checkbox-wrapper {
    @apply flex items-start;
  }

  .checkbox-label {
    @apply font-sans text-base ml-3
  }

  .checkbox {
    @apply bg-white border-gray-300 accent-transparent checked:accent-russian-green-400 h-5 w-5 rounded hover:scale-125 transition duration-500 hover:duration-300;
  }

  .form-field-wrapper {
    @apply flex justify-between items-center py-1 font-sans;
  }

  .form-field-input {
    @apply appearance-none border rounded py-2 px-3 text-raisin-900 leading-tight focus:outline-none focus:shadow focus:border-space-cadet-900 transition duration-500;
  }

  input.form-field-input::-webkit-outer-spin-button,
  input.form-field-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"].form-field-input {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield;
  }

  .form-field-select {
    @apply block appearance-none bg-white rounded border py-2 px-3 pr-8 leading-tight focus:outline-none focus:shadow focus:border-space-cadet-900 transition duration-500;
  }

  .form-field-select-pointer {
    @apply pointer-events-none absolute inset-y-0 right-0 flex items-center text-gray-700;
  }

  .image-gallery-image {
    max-height: calc(100vh - 110px) !important;
  }
}